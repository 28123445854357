<template>
    <div>
	<div style="text-align:center;padding-top:20px"><span data-v-70bf66e2="" class="effra-font">برعاية لطيفة من الرائعين في: <u><a data-v-70bf66e2="" href="https://wasetshera.com/?myad=53468" class="effra-font">وسيط شراء</a></u> و <u><a data-v-70bf66e2="" href="https://knaree.com/" class="effra-font">كناري</a></u> و <u><a data-v-70bf66e2="" href="https://rmmmz.com/" class="effra-font">رمز</a></u></span></div>
        <div class="lg:w-4/12 md:w-10/12 mt-4 w-full text-center p-2 mx-auto">
            <router-link to="/" class="px-2 text-[14px] font-bold text-[#785e60] cursor-pointer">{{ $t('home') }}</router-link>
            <!-- <router-link to="/privacy" class="px-2 text-[14px] font-bold text-[#785e60] cursor-pointer">{{ $t('privacy') }}</router-link> -->
            <a href="mailto:info@kwakeb.com" class="px-2 text-[14px] font-bold text-[#785e60] cursor-pointer">تواصل معنا</a>
            <router-link :to="{name: 'simple-page', params: { simple: true }}" class="px-2 text-[14px] font-bold text-[#785e60] cursor-pointer">صفحة بسيطة</router-link>
        </div>

        <div v-if="false" class="flex justify-center items-center gap-4 mt-4 mb-6">
            <img
                src="../assets/facebook.png"
                class="w-10 h-10 bg-gray-400 p-1 rounded-full"
                alt=""
            />

            <img
                src="../assets/twitter.png"
                class="w-10 h-10 bg-gray-400 p-1 rounded-full"
                alt=""
            />

            <img
                src="../assets/stripe-climate-badge.png"
                class="w-10 h-10 bg-gray-400 p-1 rounded-full"
                alt=""
            />
        </div>

     

        <div class="text-center my-4 text-[#785e60] text-xs">
        <!-- ©{{ $t('title') }} 2022. {{ $t('all_rights_reserved') }}. --> @Alpomodor
        </div>
    </div>
</template>