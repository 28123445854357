<template>
    <div class="">
        <div class="md:w-[620px] w-full mx-auto py-[16px] px-[12px]">
            <router-link to="/" class="inline text-[20px] md:text-[28px] text-[#d95550] decoration-[#d95550] decoration-solid decoration-auto font-bold leading-none" style="text-size-adjust: none !important">
                {{ $t('title') }}
            </router-link>
        </div>
        <hr />
        <div class="md:w-[620px] w-full mx-auto p-[12px]">
            <div class="w-full pt-6 md:pt-12">
                <h1 class="md:text-[34px] text-[24px] leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('privacy_policy') }}
                </h1>
                <p class="pt-10 text-[#785e60] text-[16px] md:text-[18px]">{{ $t('privacy_policy_effectivity') }}</p>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">{{ $t('privacy_policy_paragraph_1') }}</p>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('privacy_policy_paragraph_2') }}
                </p>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">{{ $t('privacy_policy_paragraph_3') }}</p>
            </div>

            <div class="mt-8">
                <h2 class="text-[20px] md:text-[24px] leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('information_collection_and_use') }}
                </h2>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('information_collection_and_use_paragraph_1') }}
                </p>
                <h2 class="py-4 text-[18.72px] leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('types_of_data_collected') }}
                </h2>
                <h2 class="leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('personal_data') }}
                </h2>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('personal_data_paragraph_1') }}
                </p>
                <p class="py-4 px-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('cookies_and_usage_data') }}
                </p>
                <h2 class="leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('usage_data') }}
                </h2>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('usage_data_paragraph_1') }}
                </p>
                <h2 class="leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('tracking_and_cookies_data') }}
                </h2>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('tracking_and_cookies_data_paragraph_1') }}
                </p>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('tracking_and_cookies_data_paragraph_2') }}
                </p>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('tracking_and_cookies_data_paragraph_3_part_1') }} <span class="text-[#d95550] font-bold">{{ $t('tracking_and_cookies_data_paragraph_3_part_2') }}</span>.
                </p>
                <p class="py-2 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('tracking_and_cookies_data_paragraph_4') }}
                </p>
                <ul class="py-2 px-6 text-[#785e60] text-[16px] md:text-[18px]">
                    <li class="py-1"><b>{{ $t('session_cookies') }}</b> {{ $t('session_cookies_line') }}</li>
                    <li class="py-1"><b>{{ $t('preference_cookies') }}</b> {{ $t('preference_cookies_line') }}</li>
                    <li class="py-1"><b>{{ $t('security_cookies') }}</b> {{ $t('security_cookies_line') }}</li>
                </ul>

                <h2 class="pt-8 pb-4 text-[20px] md:text-[24px] leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('use_of_data') }}
                </h2>
                <p class="py-2 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('use_of_data_paragraph_1') }}
                </p>
                <ul class="py-2 px-6 text-[#785e60] text-[16px] md:text-[18px]">
                    <li class="py-1">{{ $t('use_of_data_line_1') }}</li>
                    <li class="py-1">{{ $t('use_of_data_line_2') }}</li>
                    <li class="py-1">{{ $t('use_of_data_line_3') }}</li>
                    <li class="py-1">{{ $t('use_of_data_line_4') }}</li>
                    <li class="py-1">{{ $t('use_of_data_line_5') }}</li>
                    <li class="py-1">{{ $t('use_of_data_line_6') }}</li>
                    <li class="py-1">{{ $t('use_of_data_line_7') }}</li>
                </ul>

                <h2 class="pt-8 text-[20px] md:text-[24px] leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('transfer_of_data') }}
                </h2>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('transfer_of_data_paragraph_1') }}
                </p>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('transfer_of_data_paragraph_2') }}
                </p>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('transfer_of_data_paragraph_3') }}
                </p>
                <p class="py-4 text-[#785e60] text-[16px] md:-[18px]">
                    {{ $t('transfer_of_data_paragraph_4') }}
                </p>

                <h2 class="pt-8 pb-4 text-[20px] md:text-[24px] leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('disclosure_of_data') }}
                </h2>
                <h2 class="text-[18.72px] leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('legal_requirements') }}
                </h2>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('legal_requirements_paragraph_1') }}
                </p>
                <ul class="py-4 px-6 text-[#785e60] text-[16px] md:text-[18px]">
                    <li class="py-1">{{ $t('legal_requirements_line_1') }}</li>
                    <li class="py-1">{{ $t('legal_requirements_line_2') }}</li>
                    <li class="py-1">{{ $t('legal_requirements_line_3') }}</li>
                    <li class="py-1">{{ $t('legal_requirements_line_4') }}</li>
                    <li class="py-1">{{ $t('legal_requirements_line_5') }}</li>
                </ul>

                <h2 class="pt-6 text-[20px] md:text-[24px] leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('security_of_data') }}
                </h2>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('security_of_data_paragraph_1') }}
                </p>

                <h2 class="pt-4 text-[20px] md:text-[24px] leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('service_providers') }}
                </h2>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('service_providers_paragraph_1') }}
                </p>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('service_providers_paragraph_2') }}
                </p>

                <h2 class="pt-6 text-[20px] md:text-[24px] leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('changes_to_this_privacy_policy') }}
                </h2>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('changes_to_this_privacy_policy_paragraph_1') }}
                </p>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('changes_to_this_privacy_policy_paragraph_2') }}
                </p>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('changes_to_this_privacy_policy_paragraph_3') }}
                </p>

                <h2 class="pt-6 text-[20px] md:text-[24px] leading-10 text-[#541c1f] font-bold leading-0">
                    {{ $t('contact_us') }}
                </h2>
                <p class="py-4 text-[#785e60] text-[16px] md:text-[18px]">
                    {{ $t('contact_us_paragraph_1') }}
                </p>
                <p class="py-4 px-4 text-[#785e60] text-[16px] md:text-[18px]">
                    Info@pomodoroy.com
                </p>
            </div>
        </div>
        <hr />
        <PageFooter />
    </div>
</template>

<script>
import PageFooter from '@/components/PageFooter.vue';

export default {
    components: {
        PageFooter
    }
}
</script>