import { createWebHistory, createRouter } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import PrivacyPage from "@/views/PrivacyPage.vue";

const routes = [
  {
    path: "/",
    name: "home-page",
    component: HomePage,
  },
  {
    path: "/app",
    name: "simple-page",
    component: HomePage,
  },
  {
    path: "/privacy",
    name: "privacy-page",
    component: PrivacyPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;